<template>
  <hcc-modal
    class="close-modal"
    :title="modalTitle"
    :name="name"
    @opened="open"
    @confirm="onConfirm"
    @closed="onClose"
    :width="width"
    :confirmDisableButton="!isValid"
  >
    <div class="close-modal--body">
      <hcc-select
        :label="$t('messages.action')"
        ref="categoryList"
        class="close-modal--element"
        custom-class="expanded"
        :options="actionType"
        :option-label="'name'"
        :track-by="'id'"
        :requiredInput="true"
        v-model="action"
        :disabled="closeAll"
      />
      <template v-if="!isCancel && action">
        <hcc-select
          :label="$t('team.online')"
          ref="categoryList"
          class="close-modal--element"
          custom-class="expanded"
          :options="agents"
          option-label="name"
          :track-by="'id'"
          :requiredInput="true"
          v-model="agentSelected"
        />
        <hcc-select
          label="Campaña"
          ref="categoryList"
          class="close-modal--element"
          custom-class="expanded"
          :options="campaigns"
          option-label="name"
          :track-by="'id'"
          :requiredInput="true"
          v-model="campaignSelected"
        />
      </template>
      <template v-if="isCancel">
        <hcc-select
          :label="$t('messages.category')"
          ref="categoryList"
          class="close-modal--element"
          custom-class="expanded"
          :options="categories"
          option-label="title"
          :track-by="'id'"
          :requiredInput="true"
          v-model="selectedCategory"
        />
        <hcc-select
          v-if="showSubcategories"
          :multiple="true"
          :label="$t('messages.subcategory')"
          class="close-modal--element"
          custom-class="expanded"
          :options="subcategories"
          option-label="title"
          track-by="id"
          v-model="selectedSubcategory"
          :allow-empty="true"
          :close-on-select="true"
          :limit="1"
        />
        <hcc-textarea
          :label="$t('messages.comments')"
          class="close-modal--element"
          name="tx-commentary"
          :rows="8"
          v-model.trim="commentary"
        />
      </template>
    </div>
  </hcc-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccTextarea from '@/components/shared/HccTextarea/index.vue';
import QueryAgents from '@/graphql/queries/agents/getAgents.gql';
import Tags from '@/graphql/queries/campaigns/Tags.gql';
import executeQuery from '@/utils/gql-api';

export default {
  props: {
    conversation: {
      required: false,
      type: Object,
    },
    closeAll: {
      type: Boolean,
      default: false,
    },
    campaignIdQueue: {
      type: String,
    },
    title: {
      type: String,
      default: 'Reasignar o cerrar conversación',
    },
    width: {
      type: Number,
      default: 400,
    },
    name: {
      type: String,
      default: 'action-conversation',
    },
  },
  components: {
    HccModal,
    HccSelect,
    HccTextarea,
  },
  data() {
    return {
      commentary: '',
      tags: [],
      agents: [],
      action: null,
      agentSelected: null,
      campaignSelected: null,
      selectedCategory: null,
      selectedSubcategory: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'shared/userInfo',
    }),
    categories() {
      return this.tags.filter(c => c.type === 'Category');
    },
    subcategories() {
      if (this.selectedCategory !== null) {
        const subcategorie = this.tags.filter(({ type, parent }) => (type === 'Subcategory' && parent == null)
          || (type === 'Subcategory' && parent === this.selectedCategory.id));
        return subcategorie;
      }
      return [];
    },
    showSubcategories() {
      return this.subcategories.length > 0;
    },
    actionId() {
      switch (this.action.key) {
        case 'cancel':
          return 'CLOSED';
        case 'transfer':
          return 'TRANSFER';
        default:
          return '';
      }
    },
    isValid() {
      return this.isCancel ? this.selectedCategory !== null : this.isValidTransfer;
    },
    isValidTransfer() {
      return this.agentSelected !== null && this.campaignSelected !== null;
    },
    actionType() {
      return [
        { id: 1, name: this.$t('shared-components.table.cancel'), key: 'cancel' },
        { id: 2, name: this.$t('shared-components.table.reasign'), key: 'transfer' },
      ];
    },
    isCancel() {
      return this.action && this.action.key === 'cancel';
    },
    campaignId() {
      return this.conversation ? this.conversation.campaign?.id : this.campaignIdQueue;
    },
    agentId() {
      return this.conversation ? this.conversation.agent?.id : '';
    },
    modalTitle() {
      return this.title ? this.title : this.$t('messages.close-reasign');
    },
    campaigns() {
      return this.agentSelected?.campaigns || [];
    },
    status() {
      return this.conversation ? this.conversation.status.sqlId : 0;
    },
    isChatActive() {
      return [2, 3].includes(this.status);
    },
  },
  watch: {
    selectedCategory() {
      this.selectedSubcategory = [];
    },
    agentSelected() {
      this.campaignSelected = null;
    },
  },
  methods: {
    async fetchCategories() {
      const result = await executeQuery('tagsByCampaign', Tags, { campaign: this.campaignId });
      this.tags = result;
    },
    async fetchAgents() {
      const result = await executeQuery(
        'getOnlineAgents',
        QueryAgents,
        { campaignId: this.campaignId },
        false,
      );
      const filterAgents = this.selectAvailablesAgents(result);
      this.agents = filterAgents;
    },
    selectAvailablesAgents(agents) {
      return !this.isChatActive
        ? agents
        : agents.filter(({ id }) => id !== this.agentId);
    },
    onClose() {
      this.action = null;
      this.commentary = '';
      this.agentSelected = null;
      this.selectedCategory = null;
    },
    onConfirm() {
      const categoryId = this.selectedCategory
        ? this.selectedCategory.id
        : null;

      const subcategoryId = this.selectedSubcategory.length > 0
        ? this.selectedSubcategory.map(({ id }) => id)
        : [];

      let payload = {};
      if (this.closeAll) {
        payload = this.closeByCampaign(categoryId, subcategoryId);
      } else {
        payload = this.prepareCancelTransfer(categoryId, subcategoryId);
      }

      this.$emit('confirm', payload);
    },
    open() {
      this.fetchCategories();
      if (this.closeAll) {
        [this.action] = this.actionType;
      } else {
        this.fetchAgents();
      }
    },
    prepareCancelTransfer(categoryId, subcategoryId) {
      let user = this.user.id;

      if (this.conversation.agent && this.conversation.agent.id) {
        user = this.conversation.agent.id;
      }

      const payload = this.isCancel ? {
        fromUser: user,
        action: this.actionId,
        conversationId: this.conversation.id,
        categoryId,
        subcategoryId,
        comments: this.commentary,
      } : {
        fromUser: user,
        action: this.actionId,
        conversationId: this.conversation.id,
        toAgentId: this.agentSelected.id,
        campaignId: this.campaignSelected.id,
      };

      return payload;
    },
    closeByCampaign(categoryId, subcategoryId) {
      let tags = [];
      if (categoryId) {
        tags.push(categoryId);
      }

      tags = tags.concat(subcategoryId);

      return {
        tags,
        comments: this.commentary,
        campaign: this.campaignId,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_actionsConversationModal";
</style>
